import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import ProductGrid from '../../ProductList/ProductGrid';
import * as AppActions from '../../../AppActions';

export default function Products(props) {
  const {data} = props;
  const {
    title,
    items = [],
    bg_color = 'white',
    id = 'products',
    ext_style = '',
  } = data;
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    async function fetchProducts() {
      try {
        let productIds = items.map((it) => it.product);
        let products = [];

        let _products = await JStorage.fetchDocuments(
          'product',
          {
            _id: {
              $in: [
                ...productIds.map((id) => ({
                  $oid: id,
                })),
              ],
            },
          },
          null,
          null,
          null,
          {anonymous: true},
        );

        for (let id of productIds) {
          let p = _products.find((p) => p.id === id);
          if (p) {
            products.push(p);
          }
        }

        setProducts(products);
      } catch (ex) {}
    }

    fetchProducts();
  }, [items]);

  return (
    <Wrapper bg_color={bg_color} id={id} ext_style={ext_style}>
      <h2 className="title">{title || 'Products'}</h2>
      <div className="product-list">
        <ProductGrid
          products={products}
          prefixPath={'/products'}
          onItemClick={(item) => AppActions.navigate(`/product/?id=${item.id}`)}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  background-color: ${(props) => props.bg_color};
  padding: 80px 5vw;
  min-height: 300px;

  & > h2.title {
    font-size: 36px;
    margin-bottom: 30px;
    text-align: center;
  }

  & > .product-list {
    margin: 0 auto;
    max-width: 800px;
  }

  & > .btn {
    font-size: 20px;
    margin: 0 auto;
  }

  ${(props) => props.ext_style}
`;
