import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import Carousel, {SWIPE_STATE} from '../../../Components/Carousel';
import {ChevronRight} from '@styled-icons/evil/ChevronRight';
import {ChevronLeft} from '@styled-icons/evil/ChevronLeft';
import * as Util from '../Util';

function getCarouselSize(dimension) {
  if (dimension?.innerWidth > 1200) {
    return {
      width: 720,
      height: 720,
    };
  } else if (dimension?.innerWidth > 720) {
    return {
      width: 480,
      height: 480,
    };
  }

  return {
    width: 320,
    height: 320,
  };
}

export default function ScreenshotCarousel(props) {
  const [screenDimension] = useOutlet('dimension');
  const {items} = props;

  const size = getCarouselSize(screenDimension);

  return items ? (
    <Carousel
      key={size.width}
      width={size.width}
      height={size.height}
      data={items}
      autoSwipeDuration={0}
      renderItem={({item, dimension, swipeStateRef}) => {
        const {width, height} = dimension;
        return (
          <a
            style={{width, height, position: 'relative', display: 'block'}}
            href={item.url}
            draggable={false}
            rel="noreferrer"
            target="_blank">
            <img
              src={item.image}
              draggable={false}
              alt="banner-item"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 12,
                overflow: 'hidden',
                userSelect: 'none',
              }}
              onClick={(e) => {
                if (swipeStateRef.current === SWIPE_STATE.swipe) {
                  e.preventDefault();
                  return;
                }

                if (!item.url) {
                  e.preventDefault();
                  return;
                }

                e.preventDefault();
                Util.onLinkClick(item.url);
              }}
            />
          </a>
        );
      }}
      renderNext={(props) => {
        const {currIdx, goToIndex} = props;
        return (
          <SimpleNext onClick={() => goToIndex(currIdx + 1)} {...props}>
            <ChevronRight size={32} color={'#888'} />
          </SimpleNext>
        );
      }}
      renderPrev={(props) => {
        const {currIdx, goToIndex} = props;
        return (
          <SimplePrev onClick={() => goToIndex(currIdx - 1)} {...props}>
            <ChevronLeft size={32} color={'#888'} />
          </SimplePrev>
        );
      }}
      renderDots={(props) => {
        const {currIdx, goToIndex} = props;
        return (
          <SimpleDots onClick={() => goToIndex(currIdx - 1)} {...props}>
            {Array.from({length: items.length}).map((_, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    backgroundColor: idx === currIdx ? '#888' : 'white',
                  }}
                />
              );
            })}
          </SimpleDots>
        );
      }}
    />
  ) : (
    <div style={{width: 600, height: 300}} />
  );
}

const SimpleNext = styled.div`
  position: absolute;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  text-align: center;
  right: -21px;
  top: calc(50% - 21px);
  border: 1px solid #888;
  background-color: white;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SimplePrev = styled.div`
  position: absolute;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  text-align: center;
  left: -21px;
  top: calc(50% - 21px);
  border: 1px solid #888;
  background-color: white;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SimpleDots = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    width: 12px;
    height: 12px;
    margin: 5px;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.45);
  }
`;
