import React from 'react';
import styled from 'styled-components';
import {Button, Result, Space} from 'antd';
import Constants from '../../constants';

const Empty = (props) => {
  const config = Constants.EMPTY_CONFIG;

  return (
    <Wrapper>
      <Result
        icon={<img src={config.image} alt="empty" />}
        title={config.title}
        subTitle={config.subtitle}
        extra={
          <Space direction="vertical">
            <Button onClick={config.onButtonClick}>{config.buttonText}</Button>
          </Space>
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 150px;
  }
`;

export default Empty;
