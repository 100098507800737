import React from 'react';
import styled from 'styled-components';
import * as Util from '../Util';

export default function GeneralGrid(props) {
  const {data = {}} = props;
  const {title, items, bg_color = 'white', id = 'grid', ext_style = ''} = data;

  return (
    <Wrapper bg_color={bg_color} id={id} ext_style={ext_style}>
      <div className="content">
        <h2 className="title" data-delighter="start:0.8;">
          {title || 'Grid'}
        </h2>
        <div className="items">
          {(items || []).map((item, idx) => {
            return (
              <div key={idx}>
                <figure>
                  <img src={item.image} alt="logo" />
                  <div className="on-hover">
                    <a
                      className="btn"
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        Util.onLinkClick(item.url);
                      }}>
                      {item.btn_text || '前往查看'}
                    </a>
                  </div>
                </figure>
                <div className="title">{item.title}</div>
                <div className="text">{item.text}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  overflow: hidden;
  background-color: ${(props) => props.bg_color};

  & > .content {
    max-width: 1280px;
    margin: 0 auto;
    padding: 60px 5vw 60px 5vw;

    & > .title {
      font-size: 40px;
      text-align: center;
      margin-bottom: 20px;

      &.delighter {
        transition: all 300ms ease-out;
        transform: translateX(300px);
        opacity: 0;

        &.started {
          transform: translateX(0px);
          opacity: 1;
        }
      }
    }

    & > .items {
      display: grid;
      grid-template-columns: repeat(auto-fit, 320px);
      grid-gap: 30px;
      justify-content: center;

      & > * {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > figure {
          border: 2px solid #ccc;
          border-radius: 8px;
          overflow: hidden;
          width: 320px;
          height: 240px;
          position: relative;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 300ms;
            margin-bottom: 12px;
          }

          & > .on-hover {
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0%;
            transition: all 200ms;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:hover > .on-hover {
            height: 100%;
          }
        }

        & .title {
          font-size: 24px;
          margin-bottom: 6px;
        }

        & .btn {
          display: inline-block;
          margin-top: 18px;
        }
      }
    }
  }

  ${(props) => props.ext_style}
`;
