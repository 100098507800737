import React from 'react';
import styled from 'styled-components';
import * as Util from '../Util';

export default function Banner(props) {
  const {data} = props;
  const {
    title,
    text,
    btn_text,
    url,
    bg_color = 'rgb(255, 57, 0, 64%)',
    id = 'banner',
    ext_style = '',
  } = data;

  return (
    <Wrapper bg_color={bg_color} id={id} ext_style={ext_style}>
      <div className="content">
        <h2 className="title">{title || 'Banner'}</h2>

        {text && <div className="text">{text}</div>}

        {btn_text && url && (
          <button className="btn" onClick={Util.onLinkClick.bind(null, url)}>
            {btn_text || 'GO'}
          </button>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  background-color: ${(props) => props.bg_color};
  padding: 80px 0 80px 0;

  & > .content {
    max-width: 1024px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h2.title {
      font-size: 40px;
      color: white;
      margin-bottom: 5px;
      text-align: center;
    }

    & > .text {
      font-size: 20px;
      color: #eee;
      text-align: center;
      margin-bottom: 20px;
    }

    & > .btn {
      font-size: 24px;
      margin: 0 auto;
      animation: heartbeat 1.6s infinite;
      background-color: white;
    }
  }

  ${(props) => props.ext_style}
`;
