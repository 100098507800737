import React from 'react';
import styled from 'styled-components';
import * as Util from '../Util';

export default function Hero(props) {
  const {data = {}} = props;
  const {
    title,
    subtitle,
    btn_text,
    fg_image,
    bg_image,
    url,
    id = 'hero',
    ext_style = '',
  } = data;

  return (
    <Wrapper bgImage={bg_image} id={id} ext_style={ext_style}>
      <div className="content">
        <div className="text">
          <h2 className="title">{title || 'Title'}</h2>
          <div className="subtitle">{subtitle || 'Subtitle'}</div>
          <button className="btn" onClick={Util.onLinkClick.bind(null, url)}>
            {btn_text || 'Action'}
          </button>
        </div>

        {fg_image && (
          <div className="image">
            <img src={fg_image} alt="logo" />
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding: 80px 6vw;
  min-height: 80vh;
  background-image: url('${(props) => props.bgImage}');
  background-repeat: none;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;

  & > .content {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    & > .text {
      flex: 420px 1 1;

      & > .title {
        color: white;
        font-size: 53px;
        text-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
      }

      & > .subtitle {
        color: white;
        font-size: 24px;
        text-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
      }

      & > .btn {
        font-size: 20px;
        margin: 20px 0;
        animation: heartbeat 1.6s infinite;
        backgrounc-color: white;
      }
    }

    & > .image {
      padding: 30px;
      flex: 420px 0 0;

      & > img {
        width: 100%;
      }
    }

    @media screen and (max-width: 800px) {
      & > .text {
        flex: 300px 1 1;
      }

      & > .image {
        flex: 300px 0 0;
      }
    }
  }

  ${(props) => props.ext_style}
`;
