import React from 'react';
import styled from 'styled-components';
import ScreenshotCarousel from './ScreenshotCarousel';

export default function GeneralCarousel(props) {
  const {data = {}} = props;
  const {
    title,
    items,
    bg_color = 'white',
    id = 'carousel',
    ext_style = '',
  } = data;
  return (
    <Wrapper bg_color={bg_color} id={id} ext_style={ext_style}>
      <h2 className="title">{title || 'Carousels'}</h2>
      <div className="content">
        <ScreenshotCarousel items={items || []} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  background-color: ${(props) => props.bg_color};
  padding: 80px 0;

  & > h2.title {
    text-align: center;
    font-size: 40px;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-gap: 10px;
    justify-content: center;
    max-width: 1024px;
    margin: 0 auto;
    margin-bottom: 40px;

    &::before,
    &::after {
      content: '';
      height: 1px;
      background-color: #aaa;
      align-self: center;
    }
  }

  & > .content {
    display: flex;
    z-index: 1;
    position: relative;
    justify-content: center;
  }

  ${(props) => props.ext_style}
`;
