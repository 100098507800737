import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import Empty from '../../../Components/Empty';
import ProductItemType1 from './ProductItemType1';
import ArticleItemType1 from './ArticleItemType1';

function ProductGrid(props) {
  const {products, onItemClick, prefixPath} = props;
  const [categroies] = useOutlet('categories');
  const [articleCategories] = useOutlet('articleCategories');
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  if (products.length <= 0) {
    return <Empty />;
  }

  if (
    prefixPath.indexOf('products') >= 0 ||
    prefixPath.indexOf('sales-event') >= 0
  ) {
    return (
      <ProductGridWrapper mobile={mobile}>
        {products.map((product, i) => {
          if (categroies.meta_data.item_ui_type === 'type1') {
            return (
              <ProductItemType1
                key={product.id}
                mobile={mobile}
                product={product}
                onClick={(evt) => onItemClick(product, evt)}
              />
            );
          }

          return (
            <ProductItemType1
              key={product.id}
              mobile={mobile}
              product={product}
              onClick={(evt) => onItemClick(product, evt)}
            />
          );
        })}

        {new Array(products.length % 4).fill(0).map((item, key) => (
          <div className="filler" key={key} />
        ))}
      </ProductGridWrapper>
    );
  } else if (prefixPath.indexOf('articles') >= 0) {
    return (
      <ArticleGridWrapper>
        {products.map((product, i) => {
          if (articleCategories.meta_data.item_ui_type === 'type1') {
            return <ArticleItemType1 key={i} item={product} />;
          }

          return <ArticleItemType1 key={i} item={product} />;
        })}

        {[0, 1, 2].map((_) => (
          <ArticleItemType1 extraStyle="height: 0px;" />
        ))}
      </ArticleGridWrapper>
    );
  }
}

const ProductGridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.mobile ? 'center' : 'space-between')};
  padding: ${(props) => (props.mobile ? 0 : 'var(--basePadding)')};

  & > .filler {
    width: ${(props) => (props.mobile ? '160px' : '200px')};
    height: 1px;
    margin: 10px;
  }
`;

const ArticleGridWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media screen and (max-width: ${420 - 1}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export default ProductGrid;
