import React from 'react';
import styled from 'styled-components';
import * as Util from '../Util';

export default function Highlight(props) {
  const {data} = props;
  const {
    title,
    text,
    bubble_text,
    avatar_image,
    avatar_name,
    ext_link_prefix,
    ext_link_url,
    ext_link_text,
    bg_color = 'white',
    content_bg_color = '#fff5f5',
    id = 'highlight',
    ext_style = '',
  } = data;

  return (
    <Wrapper
      id={id}
      bg_color={bg_color}
      content_bg_color={content_bg_color}
      ext_style={ext_style}>
      <div className="content">
        <h2 className="title">{title || 'Highlight'}</h2>
        <div className="description">{text || 'Highlight text'}</div>
        <div style={{flex: 1}} />
        <div className="dialog">
          <div className="bubble">{bubble_text || 'Bubble text'}</div>
          <div className="avatar">
            <img src={avatar_image || '/images/revicon_512.png'} alt="avatar" />
            <div className="text">{avatar_name || 'Avatar Name'}</div>
          </div>
        </div>
        <div className="link">
          {ext_link_prefix || '詳情請見'}
          <a
            href={ext_link_url}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              Util.onLinkClick(ext_link_url);
            }}>
            {ext_link_text || '此處'}
          </a>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  background-color: ${(props) => props.bg_color};
  // margin-top: -220px;
  padding: 80px 20px;

  & > .content {
    // transform: translateY(220px);
    max-width: 800px;
    margin: 0px auto;
    border-radius: 24px;
    background-color: ${(props) => props.content_bg_color};
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.33);
    padding: 60px 30px;
    min-height: 600px;
    display: flex;
    flex-direction: column;

    & > h2.title {
      font-size: 40px;
      margin-bottom: 5px;
    }

    & > .description {
      font-size: 22px;
    }

    & > .dialog {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      & > .bubble {
        flex: 1;
        margin: 0 60px 20px 0;
        background-color: white;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
        padding: 20px;
        font-size: 18px;
        transform: translateY(36px);
      }

      & > .avatar {
        width: 80px;
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        align-items: center;

        & > img {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          border: 2px solid white;
          object-fit: cover;
          margin-bottom: 10px;
        }

        & > .text {
          text-align: center;
        }
      }
    }

    & > .link {
      margin-top: 30px;
      font-size: 16px;
      color: #888;
      & > a {
        padding-left: 8px;
        display: inline-block;
        color: black;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  ${(props) => props.ext_style}
`;
