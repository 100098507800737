import React from 'react';
import styled from 'styled-components';
import Preview from 'rev.sdk.js/Components/RichTextPreview';

export default function RichText({data}) {
  const {richtext, ext_style, id, ui_type} = data;

  if (ui_type === 'type_2') {
    return (
      <Wrapper id={id} ext_style={ext_style}>
        <div className="content">
          <Preview content={richtext.content} />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper id={id} ext_style={ext_style}>
      <Preview content={richtext.content} />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  max-width: var(--contentMaxWidth);
  margin: 0 auto;

  & > .content {
    max-width: 800px;
    margin: 40px auto;
    border-radius: 24px;
    background-color: #fff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.33);
    padding: 20px;
  }

  ${(props) => props.ext_style}
`;
