import * as AppActions from '../../AppActions';

export function onLinkClick(href) {
  if (!href) {
    return;
  }

  if (href.indexOf('#') === 0) {
    const section = document.querySelector(href);
    if (section) {
      window.scrollTo({top: section.offsetTop, behavior: 'smooth'});
    }
  } else if (href.indexOf('/') === 0) {
    AppActions.navigate(href);
  } else {
    window.open(href, '_blank');
  }
}
