import React from 'react';
import styled from 'styled-components';

export default function List(props) {
  const {data = {}} = props;
  const {
    title,
    items,
    bg_color = 'white',
    numbered = false,
    id = 'list',
    ext_style = '',
  } = data;

  return (
    <Wrapper bg_color={bg_color} id={id} ext_style={ext_style}>
      <div className="content">
        <h2 className="title">{title || 'List'}</h2>
        <div className="items">
          {(items || []).map((item, idx) => {
            return (
              <div key={idx} data-delighter="start:0.8;">
                <img src={item.image || '/images/revicon_512.png'} alt="logo" />
                <div className="content">
                  <div className="title">
                    {numbered && <div className="idx">{idx + 1}</div>}
                    <div>{item.title}</div>
                  </div>
                  <div className="description">{item.description}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  background-color: ${(props) => props.bg_color};
  padding: 100px 5vw;
  overflow: hidden;

  & > .content {
    max-width: 1024px;
    margin: 0 auto;

    & > h2.title {
      font-size: 40px;
      text-align: center;
      margin-bottom: 40px;
    }

    & > .items {
      & > * {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        & > img {
          width: 144px;
          height: 144px;
          margin: 20px;
        }

        & > .content {
          margin: 20px;
          flex: 300px 1 1;

          & > .title {
            display: flex;
            font-size: 24px;
            margin-bottom: 10px;
            align-items: center;

            & > .idx {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: orange;
              color: white;
              margin-right: 6px;
            }
          }

          & > .description {
            font-size: 20px;
            color: #666;
          }
        }

        &:nth-child(even) {
          flex-direction: row-reverse;
          &.delighter {
            transform: translateX(300px);
          }
        }

        &.delighter {
          transition: all 300ms ease-out;
          transform: translateX(-300px);
          opacity: 0;

          &.started {
            transform: translateX(0px);
            opacity: 1;
          }
        }
      }
    }
  }

  ${(props) => props.ext_style}
`;
