import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import ReactDelighters from 'rev.sdk.js/Components/ReactDelighters';
import Hero from './Hero';
import Grid from './Grid';
import List from './List';
import Carousel from './Carousel';
import Articles from './Articles';
import Products from './Products';
import Banner from './Banner';
import Highlight from './Highlight';
import RichText from './RichText';

export default function PageBuilder(props) {
  return (
    <ReactDelighters>
      <PageBuilderImpl {...props} />
    </ReactDelighters>
  );
}

function PageBuilderImpl(props) {
  const [_landing] = useOutlet('landing');
  const {
    pageContext: {sections},
  } = props;

  return (
    <Wrapper>
      {sections.map((data, idx) => {
        if (data.type === 'hero') {
          return <Hero key={idx} data={data} />;
        } else if (data.type === 'grid') {
          return <Grid key={idx} data={data} />;
        } else if (data.type === 'list') {
          return <List key={idx} data={data} />;
        } else if (data.type === 'carousel') {
          return <Carousel key={idx} data={data} />;
        } else if (data.type === 'articles') {
          return <Articles key={idx} data={data} />;
        } else if (data.type === 'products') {
          return <Products key={idx} data={data} />;
        } else if (data.type === 'banner') {
          return <Banner key={idx} data={data} />;
        } else if (data.type === 'highlight') {
          return <Highlight key={idx} data={data} />;
        } else if (data.type === 'richtext') {
          return <RichText key={idx} data={data} />;
        }
        return <div key={idx} />;
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  font-family: 'Lato', sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 0;
    margin: 0;
  }

  button {
    display: block;
  }

  .btn {
    border: none;
    border-radius: 0px;
    padding: 10px 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    background-color: white;
    border-radius: 100px;
    transition: all 300ms;
    cursor: pointer;

    &:hover {
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.44);
    }

    &:active {
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.36);
    }
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes infinite-spinning {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(360deg) scale(1.2);
    }
    100% {
      transform: rotate(720deg) scale(1);
    }
  }
`;
