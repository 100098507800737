import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import ProductGrid from '../../ProductList/ProductGrid';
import * as AppActions from '../../../AppActions';

export default function Articles(props) {
  const {data} = props;
  const {
    title,
    items = [],
    bg_color = 'white',
    id = 'articles',
    ext_style = '',
  } = data;
  const [articles, setArticles] = React.useState([]);

  React.useEffect(() => {
    async function fetchArticles() {
      try {
        let articleIds = items.map((it) => it.article);
        let articles = [];

        let _articles = await JStorage.fetchDocuments(
          'Article_Default',
          {
            _id: {
              $in: [
                ...articleIds.map((id) => ({
                  $oid: id,
                })),
              ],
            },
          },
          null,
          null,
          null,
          {anonymous: true},
        );

        for (let id of articleIds) {
          let p = _articles.find((p) => p.id === id);
          if (p) {
            articles.push(p);
          }
        }

        setArticles(articles);
      } catch (ex) {}
    }

    fetchArticles();
  }, [items]);

  return (
    <Wrapper bg_color={bg_color} id={id} ext_style={ext_style}>
      <h2 className="title">{title || 'Articles'}</h2>
      <div className="product-list">
        <ProductGrid
          products={articles}
          prefixPath={'/articles'}
          onItemClick={(item) => AppActions.navigate(`/article/?id=${item.id}`)}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  background-color: ${(props) => props.bg_color};
  padding: 80px 5vw;
  min-height: 300px;

  & > h2.title {
    font-size: 36px;
    margin-bottom: 30px;
    text-align: center;
  }

  & > .product-list {
    margin: 0 auto;
    max-width: 800px;
  }

  & > .btn {
    font-size: 20px;
    margin: 0 auto;
  }

  ${(props) => props.ext_style}
`;
